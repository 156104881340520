import "../styles/globals.css";
import type { AppPropsWithLayout } from "next/app";
import { NextUIProvider } from "@nextui-org/react";
import PlausibleProvider from "next-plausible";
import { lightTheme } from "../theme/shared";

const MyApp: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <NextUIProvider theme={lightTheme}>
      <PlausibleProvider domain="mimimimi.megumi.sh" enabled={true}>
        {getLayout(<Component {...pageProps} />)}
      </PlausibleProvider>
    </NextUIProvider>
  );
};
export default MyApp;
